<template>
  <div class="p-1">
    <div v-if="showError" class="w-100 text-center pt-4">
      <h2 class="mb-1">Page Not Found 🕵🏻‍♀️</h2>
      <p class="mb-2">
        Oops! 😖 The requested URL was not found on this server.
      </p>
      <b-img fluid :src="imgUrl" alt="Error page" />
    </div>
    <template v-else>
      <!-- kent -->
      <template
        v-if="!$route.params.obj && db == '/r6Q0RYpjkcYn0rZGVa/OytKs xrVo3L'"
      >
        <inquiry-other
          :db="$route.query.db"
          :cID="$route.query.cId"
          :school="school"
          :classes="classesOptions"
        />
      </template>
      <!-- veritas -->
      <template
        v-else-if="!$route.params.obj && db == '/r6Q0RYpjkepcuY894yPgQ=='"
      >
        <inquiry-veritas
          :db="$route.query.db"
          :cID="$route.query.cId"
          :school="school"
          :classes="classesOptions"
        />
      </template>
      <template v-else>
        <b-row align-h="center">
          <b-col class="text-center" md="7">
            <b-img
              v-if="school.logo == null || school.logo == ''"
              ref="previewEl"
              src="https://cdn.cloudious.net/file-1678110140675-811622963.png"
              fluid
              height="100px"
              width="100px"
            />
            <b-img
              v-else
              ref="previewEl"
              :src="school.logo"
              fluid
              style="max-width: 280px; max-height: 150px"
            />
            <div class="mt-1 details-adjust">
              <h3>{{ school.name }}</h3>
              <h5>
                <feather-icon icon="MapPinIcon" class="mr-50" />
                {{ school.address }}
              </h5>
              <h5>
                <feather-icon icon="PhoneIcon" class="mr-50" />
                {{ school.phone || school.mobile }}
              </h5>
            </div>
          </b-col>
        </b-row>
        <b-row align-h="center" class="mt-1">
          <b-col md="7">
            <b-card class="card">
              <h3 class="">Student Information</h3>
              <hr />
              <!-- <b-row align-h="center">
                  <b-col class="ml-3" cols="4">
                    <b-form-group
                      class="text-center"
                      ref="picture"
                      :state="nameState"
                      label=" "
                      label-for="name-input"
                      invalid-feedback="Picture is required."
                    >
                      <b-media no-body ref="picture">
                        <div v-if="logoloading == true">
                          <b-spinner
                            variant="primary"
                            type="grow"
                            class="m-5"
                            label="Spinning"
                          ></b-spinner>
                        </div>
      
                        <b-media-aside>
                          <b-link>
                            <b-img
                              ref="previewEl"
                              src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                              @click="$refs.fileProfile.click()"
                              v-if="logoloading == false"
                              style="border-radius: 100px"
                              fluid
                              height="120px"
                              width="120px"
                            />
      
                          </b-link>
      
                          <b-link v-if="logoloading == 'loaded'">
                            <div class="text-right" style="margin-right: 35px">
                              <b-button
                                @click="deleteLogo()"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                class="position-absolute btn-icon"
                                variant="danger"
                              >
                                <feather-icon icon="Trash2Icon" />
                              </b-button>
                            </div>
      
                            <b-img
                              class="rcorners2"
                              :src="plus"
                              height="120px"
                              width="120px"
                            />
      
                            <br />
                          </b-link>
                          <input
                            type="file"
                            id="fileProfile"
                            hidden
                            ref="fileProfile"
                            @change="selectlogo()"
                            accept="image/*"
                          />
                        </b-media-aside>
                      </b-media>
                      <small class="mr-1 text-danger" v-if="logoerror"
                        >Logo is required.</small
                      >
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr /> -->

              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Name "
                    invalid-feedback="Student name is required."
                  >
                    <b-form-input
                      placeholder="Enter name."
                      ref="name"
                      v-model="myObj.name"
                      @focusout="CheckName()"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label="Father name"
                    invalid-feedback="Father name is required."
                  >
                    <b-form-input
                      ref="fname"
                      placeholder="Enter father name."
                      v-model="myObj.father_name"
                      @focusout="CheckFName()"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Gender"
                    invalid-feedback="School name is required."
                  >
                    <v-select
                      v-model="myObj.gender"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="genders"
                      :clearable="false"
                      input-id="categoryId"
                      ref="gender"
                      :disabled="dataloading"
                      placeholder="Select gender"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Date of Birth"
                    invalid-feedback="date of birth is required."
                    ref="dob"
                  >
                    <flat-pickr
                      ref="dob"
                      :config="config"
                      v-model="myObj.date_of_birth"
                      class="form-control"
                      placeholder="Select date of birth."
                    />
                    <!-- @on-change="CheckDob()" -->
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="this.$route.params.obj">
                <b-col md="6">
                  <b-form-group
                    label="Reg number"
                    invalid-feedback="Reg number"
                  >
                    <b-form-input
                      v-model="myObj.grNo"
                      class="form-control"
                      placeholder="Enter reg number"
                      @focusout="CheckGrNo()"
                      ref="grNo"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Family code"
                    invalid-feedback="Family code is required."
                  >
                    <b-form-input
                      type="number"
                      v-model="myObj.family_code"
                      class="form-control"
                      placeholder="Enter family code"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- <b-col md="6">
                          <b-form-group
                            label="Contact"
                            invalid-feedback="Contact number is required."
                          >
                            <b-form-input
                              placeholder="Enter contact number."
                              v-model="myObj.contact_student"
                            />
                          </b-form-group>
                        </b-col> -->
                <b-col md="12">
                  <b-form-group
                    label="Last Institute"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      placeholder="Enter last institute."
                      v-model="myObj.last_institute"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6"> </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Date of birth in words."
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      placeholder="Enter date of birth in words."
                      v-model="myObj.date_of_birth_words"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Current Address"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      placeholder="Enter current address."
                      v-model="myObj.address_current"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Permanent Address"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      placeholder="Enter permanent address."
                      v-model="myObj.address_permanent"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <!-- <b-col md="6">
                    <b-form-group
                      label="Date of registration"
                      invalid-feedback="School name is required."
                    >
                      <flat-pickr
                        :config="config"
                        v-model="myObj.date_of_registraion"
                        class="form-control"
                        placeholder="Select date of registration."
                      />
                    </b-form-group>
                  </b-col> -->

                <b-col md="6">
                  <b-form-group
                    label="B-Form"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      placeholder="Enter B-Form."
                      v-model="myObj.bform_number"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <!-- <b-col md="4">
                        <b-form-group
                          :label="
                            $store.state.userData.studentStatus == 'present'
                              ? 'Gr number'
                              : 'Reg number'
                          "
                          :invalid-feedback="
                            $store.state.userData.studentStatus == 'present'
                              ? 'Gr number is required'
                              : 'Reg number is required'
                          "
                        >
                          <b-form-input
                            v-model="myObj.grNo"
                            class="form-control"
                            :placeholder="
                              $store.state.userData.studentStatus == 'present'
                                ? 'Enter Gr number '
                                : 'Enter reg number'
                            "
                            @focusout="CheckGrNo()"
                            ref="grNo"
                          />
                        </b-form-group>
                      </b-col> -->
                <b-col md="6">
                  <b-form-group
                    :label="this.$route.params.obj ? 'Test for class' : 'Class'"
                    invalid-feedback="Class is required."
                    ref="class_admitted"
                  >
                    <v-select
                      v-model="myObj.class_admitted"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="classesOptions"
                      :clearable="false"
                      :reduce="(val) => val.id"
                      input-id="id"
                      label="name"
                      @search:blur="CheckClassAdmit()"
                      :disabled="dataloading"
                      placeholder="Select class admitted."
                      @input="FillSections()"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Section"
                    invalid-feedback="Section is required."
                    ref="section_admitted"
                  >
                    <v-select
                      v-model="myObj.section_admitted"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="admittedSections"
                      :reduce="(val) => val.id"
                      label="section"
                      :clearable="false"
                      :disabled="dataloading"
                      placeholder="Select section admitted."
                      @input="CheckAdmitSec()"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6" v-if="this.$route.params.obj">
                  <b-form-group
                    label="Test on"
                    invalid-feedback="Test date is required."
                    ref="test_date"
                  >
                    <flat-pickr
                      :config="config2"
                      v-model="myObj.test_date"
                      class="form-control"
                      placeholder="Select date for test"
                      @on-close="checktestDate()"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mt-1">
                <b-col md="12">
                  <div class="file-uploader">
                    <h5>
                      <b-spinner
                        variant="dark"
                        label="Spinning"
                        v-if="uploading"
                      ></b-spinner>
                      <span v-else> Upload Documents </span>
                    </h5>
                    <!-- {{ getFileNames === "" ? "Upload your document here" : getFileNames }} -->
                    <input
                      ref="studentdocs"
                      type="file"
                      class="file_upload"
                      @change="uploadDocument()"
                      multiple="true"
                    />
                  </div>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12">
                  <template v-for="(file, index) in documents">
                    <div class="file">
                      <div style="display: flex; align-items: center">
                        <div class="file-type">
                          {{ file.split(".").pop().toUpperCase() }}
                        </div>
                        <p class="file-name">{{ file }}</p>
                      </div>
                      <div class="file-delete">
                        <i
                          class="fa fa-trash"
                          aria-hidden="true"
                          @click="deleteDocument(index)"
                        ></i>
                      </div>
                    </div>
                  </template>
                </b-col>
              </b-row>
            </b-card>
            <b-card class="card">
              <h3 class="">Father Information</h3>
              <hr />
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Occupation"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      v-model="myObj.father_occupation"
                      placeholder="Enter Occupation"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="CNIC no "
                    invalid-feedback="CNIC is required."
                  >
                    <b-form-input
                      ref="fCnic"
                      v-model="myObj.father_cnic"
                      placeholder="Enter CNIC number"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Father's Contact"
                    invalid-feedback="Please enter correct contact number."
                  >
                    <vue-tel-input
                      v-model="myObj.contact_father"
                      :inputOptions="vueTelOptions"
                      @blur="CheckFContact()"
                    ></vue-tel-input>
                    <small class="mr-1 text-danger" v-if="fphoneerror"
                      >Phone number is required.</small
                    >
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Email"
                    invalid-feedback="Email is required."
                  >
                    <b-form-input
                      ref="fEmail"
                      v-model="myObj.father_email"
                      placeholder="Enter Email"
                    />
                  </b-form-group>
                  <!-- @focusout="CheckEmail()" -->
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Education"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      v-model="myObj.father_education"
                      placeholder="Enter Education"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Employer"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      v-model="myObj.father_employer"
                      placeholder="Enter Employer"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group label="Monthly income">
                    <b-form-input
                      type="number"
                      v-model="myObj.monthly_income"
                      placeholder="Enter monthly income"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Office Address"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      placeholder="Enter office address"
                      v-model="myObj.office_address"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
            <b-card class="card">
              <h3 class="">Mother Information</h3>
              <hr />
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Name"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      v-model="myObj.mother_name"
                      placeholder="Enter name"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label="Occupation"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      v-model="myObj.mother_occupation"
                      placeholder="Enter occupation"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="CNIC no"
                    invalid-feedback="CNIC is required."
                  >
                    <b-form-input
                      ref="mCnic"
                      v-model="myObj.mother_cnic"
                      placeholder="Enter CNIC number"
                    />
                    <!-- @focusout="CheckMCnic()" -->
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Mother's Contact"
                    invalid-feedback="Please enter correct phone number."
                  >
                    <vue-tel-input
                      :inputOptions="vueTelOptions"
                      @blur="CheckMContact()"
                      v-model="myObj.contact_mother"
                    ></vue-tel-input>
                    <small class="mr-1 text-danger" v-if="mphoneerror"
                      >Phone number is required.</small
                    >
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Email"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      v-model="myObj.mother_email"
                      placeholder="Enter email address"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Education"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      v-model="myObj.mother_education"
                      placeholder="Enter education."
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Employer"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      v-model="myObj.mother_employer"
                      placeholder="Enter employer"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Office Address"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      v-model="myObj.mother_office_address"
                      placeholder="Enter office address"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <!-- <b-col md="6">
              <b-card class="p-1 card">
                <h3 class="mt-1">Guardian Information</h3>
                <hr />
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Name"
                      invalid-feedback="School name is required."
                    >
                      <b-form-input
                        placeholder="Enter guardian name."
                        v-model="myObj.guardian_name"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Relation"
                      invalid-feedback="School name is required."
                    >
                      <b-form-input
                        placeholder="Enter guardian relation."
                        v-model="myObj.guardian_relationship"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
      
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Occupation"
                      invalid-feedback="School name is required."
                    >
                      <b-form-input
                        placeholder="Enter guardian occupation."
                        v-model="myObj.guardian_occupation"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="CNIC no"
                      invalid-feedback="Contact number is required."
                    >
                      <b-form-input
                        ref="gCnic"
                        placeholder="Enter CNIC number."
                        v-model="myObj.guardian_cnic"
                      />
                    </b-form-group>
                  </b-col>
                
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Email"
                      invalid-feedback="Contact number is required."
                    >
                      <b-form-input
                        ref="gEmail"
                        placeholder="Enter email addresss."
                        v-model="myObj.guardian_email"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Employer"
                      invalid-feedback="Contact number is required."
                    >
                      <b-form-input
                        placeholder="Enter employer."
                        v-model="myObj.guardian_employer"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Office Contact"
                      invalid-feedback="Contact number is required."
                    >
                      <b-form-input
                        placeholder="Enter office number"
                        v-model="myObj.guardian_office_phone"
                      />
                    </b-form-group>
                  </b-col>
      
                  <b-col md="6">
                    <b-form-group
                      label="Education"
                      invalid-feedback="Contact number is required."
                    >
                      <b-form-input
                        placeholder="Enter Education"
                        v-model="myObj.guardian_education"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      label="Office Address"
                      invalid-feedback="Contact number is required."
                    >
                      <b-form-input
                        placeholder="Enter office contact number"
                        v-model="myObj.guardian_office_address"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col> -->
        </b-row>
        <b-row align-h="center">
          <b-col md="7">
            <b-button
              @click="shortcutSubmit()"
              variant="success"
              :disabled="request || uploading"
              block
            >
              <b-spinner v-if="request" type="grow" small label="Loading..." />
              <span v-else>Submit</span>
            </b-button>
          </b-col>
        </b-row>
      </template>
    </template>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters } from "vuex";

import inquiryOther from "../inquiry/inquiryOther.vue";
import inquiryVeritas from "../inquiry/inquiryVeritas.vue";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BButton,
  BMediaAside,
  BImg,
  BMedia,
  BLink,
  BSpinner,
  BBadge,
  BFormFile,
  BFormTextarea,
  BProgress,
  BProgressBar,
  BFormCheckbox,
} from "bootstrap-vue";
import { resolve } from "path";
import { reject } from "q";
export default {
  props: ["selected", "id"],
  components: {
    // DropZone,
    BFormCheckbox,
    BProgress,
    BProgressBar,
    BFormTextarea,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    flatPickr,
    BCard,
    BButton,
    BMediaAside,
    BImg,
    BMedia,
    BLink,
    BSpinner,
    BBadge,
    BFormFile,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    inquiryOther,
    inquiryVeritas,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      file: [],
      mydocs: [],
      moreloading: false,
      plus: "",
      logoloading: false,
      netAmount: 0,
      dataloading: false,
      admittedSections: [],
      currentSections: [],
      classes: [],
      genders: ["Male", "Female"],
      categories: [],
      prog: {
        test_fee: false,
        test_passed: false,
        confirmed: false,
      },
      groups: ["A", "B", "O", "AB"],
      religions: ["Islam", "Christianity", "Hinduism", "Buddhists"],
      documents: [],
      uploading: false,
      school: {},
      myObj: {
        id: 0,
        grNo: "",
        family_code: null,
        name: null,
        father_name: null,
        gender: null,
        picture: null,
        date_of_birth: null,
        date_of_birth_words: null,
        date_of_admission: null,
        blood_group: null,
        nationality: null,
        religion: null,
        place_of_birth: null,
        mother_tongue: null,
        address_current: null,
        address_permanent: null,
        contact_student: null,
        contact_father: null,
        contact_mother: null,
        contact_home: null,
        contact_tutor: null,
        last_institute: null,
        last_class: null,
        last_session: null,
        last_result: null,
        last_note: null,
        class_admitted: 0,
        cls_admitted: null,
        class_current: 0,
        cls_current: null,
        section_admitted: 0,
        section_current: 0,
        roll_number: null,
        house: null,
        rfid_number: null,
        bform_number: null,
        date_of_registraion: new Date().toJSON(),
        date_of_readmission: null,
        father_cnic: null,
        father_education: null,
        father_occupation: null,
        monthly_income: null,
        office_phone: null,
        office_address: null,
        father_email: null,
        father_employer: null,
        mother_name: null,
        mother_cnic: null,
        mother_education: null,
        mother_occupation: null,
        mother_office_phone: null,
        mother_office_address: null,
        mother_email: null,
        mother_employer: null,
        guardian_name: null,
        guardian_cnic: null,
        guardian_education: null,
        guardian_occupation: null,
        guardian_office_phone: null,
        guardian_office_address: null,
        guardian_email: null,
        guardian_employer: null,
        guardian_relationship: null,
        emergency_name: null,
        emergency_contact: null,
        emergency_relationship: null,
        physical_handicap: null,
        allergies: null,
        remarks: null,
        monthly_fee: 0,
        discount_percentage: 0,
        challan_due_date: null,
        docs: "",
        status: "registered",
        campusID: this.$store.state.userData.cId,
        test_date: null,
      },
      fphoneerror: false,
      mphoneerror: false,
      phoneerror: false,
      emerPhoneerror: false,
      vueTelOptions: { placeholder: "Enter contact number" },
      emerOpt: { placeholder: "Enter emergency contact" },
      classesOptions: [],
      check: 1,
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
        // disableMobile: true,
      },
      config2: {
        enableTime: true,
        defaultHour: 9,
        altFormat: "j M, Y h:i",
        altInput: true,
        dateFormat: "Y-m-d\\TH:i:S",
      },
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkZW1vQG15c2tvb2wuYXBwIiwianRpIjoiZmRjZjdhYzQtNmY1OS00Nzg0LTkyNDItZjFjNjRhOTdjODQ0IiwiZXhwIjoxOTkzNzk2MDgwLCJpc3MiOiJteXNrb29sLmFwcCIsImF1ZCI6Im15c2tvb2wuYXBwIn0._pMXprq3AJApizQQlcrVXS3-dPyGFMi0nT9OfLQKBlo",
      db: this.$route.query.db,
      cId: this.$route.query.cId,
      showError: false,
    };
  },
  created() {
    console.log(this.$route.params.obj);
    if (this.$route.params.obj) {
      // console.log(this.$route.params.obj.inquiry.id);
      this.LoadInit();
      let data = this.$route.params.obj.inquiry;

      this.myObj.name = data.name;
      this.myObj.father_name = data.father_name;
      this.myObj.class_admitted = data.cls;
      this.myObj.address_current = data.residentialArea;
      this.myObj.contact_father = data.contact;
      this.myObj.father_cnic = data.cnic;
      this.myObj.father_email = data.email;
      this.myObj.last_institute = data.lastSchool;
      this.myObj.address_permanent = data.residentialArea;

      this.myObj.docs = data.attachments;
      if (data.attachments !== null && data.attachments !== "") {
        this.documents = data.attachments.split(",");
      } else this.documents = [];
    } else if (!this.$route.query.db || !this.$route.query.cId) {
      this.showError = true;
    }
    this.LoadData();
    this.LoadSchool();
    this.myObj.campusID = parseInt(this.cId) || this.$store.state.userData.cId;
    // console.log(this.$route.query.db, this.$route.query.cId);
  },
  // mounted() {
  // console.log("mounted");
  // this.$refs.wizard.activateAll();
  //   window.addEventListener("keydown", async (event) => {
  //     if (event.ctrlKey && event.keyCode === 83) {
  //       event.preventDefault();
  //       if (this.check == 1) {
  //         this.check = 0;
  //         console.log(this.check);
  //         this.shortcutSubmit();
  //         // this.$bvToast.toast("This GR number is already registerd!", {
  //         //   title: "Error!",
  //         //   variant: "danger",
  //         //   solid: true,
  //         // });
  //       }
  //     }
  //   });
  // },
  computed: {
    ...mapGetters({ campusID: "campusID" }),

    imgUrl() {
      let img = require("@/assets/images/pages/error.svg");
      if (this.$store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        img = require("@/assets/images/pages/error-dark.svg");
        return img;
      }
      return img;
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    // test() {

    // },
    uploadDocument(e) {
      // console.log(e.target.files);
      // this.documents = Array.from(e.target.files);
      // console.log(this.documents);
      let docs = this.$refs.studentdocs.files;
      if (docs.length != 0) {
        this.uploading = true;
        let formData = new FormData();
        docs.forEach((el) => {
          formData.append("file", el);
        });
        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            console.log(e.data);
            let resp = e.data.myresp;
            resp.forEach((el) => {
              this.documents.push(el.path);
            });
            this.myObj.docs = this.documents.join(",");
            this.uploading = false;
            // console.log(this.myObj.docs);
          });
      }
    },
    deleteDocument(index) {
      // console.log(index);
      this.documents.splice(index, 1);
      this.myObj.docs = this.documents.join(",");
      // console.log(this.myObj.docs);
    },
    CheckRequired(name) {
      var elem = this.$refs[name];
      if (this.myObj.name == "" || this.myObj.name > 20) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }

      // return (elem.state = this.$v.depObj.name.required);
    },
    saveForm() {
      // console.log(e);
      this.$bvToast.toast("This GR number is already registerd!", {
        title: "Error!",
        variant: "danger",
        solid: true,
      });
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        // resolve(true);
        this.CheckName();
        this.CheckFName();
        this.CheckDob();
        this.CheckClassAdmit();
        this.CheckClassCurrent();
        this.CheckCurrentSec();
        this.CheckAdmitSec();
        this.CheckGrNo();
        this.CheckFees();
        if (
          this.CheckName() == false ||
          this.CheckFName() == false ||
          this.CheckDob() == false ||
          this.CheckClassAdmit() == false ||
          this.CheckClassCurrent() == false ||
          this.CheckCurrentSec() == false ||
          this.CheckAdmitSec() == false ||
          this.CheckFees() == false ||
          this.CheckGrNo() == false
        ) {
          reject(false);
        } else {
          if (this.myObj.id == 0) {
            resolve(true); //
            var axios = require("axios");
            var config = {
              method: "get",
              url:
                this.$store.state.domain +
                "students/CheckGR/" +
                this.myObj.campusID +
                "/" +
                this.myObj.grNo +
                "?db=" +
                this.$store.state.userData.db,
              headers: {
                Authorization:
                  "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhYmMiLCJqdGkiOiI4NmQyMDQ3NC0xZjg5LTQ5NWItYjc2MC04NzY4NjI1MWJkYTEiLCJleHAiOjE2NzA0MjAyNzksImlzcyI6Im15c2tvb2wuYXBwIiwiYXVkIjoibXlza29vbC5hcHAifQ.jPCDB2X_5FAc9MrT-2oaFrcfM6-fwJ64isfQ_PCxzYM",
              },
            };
            console.log("----------------------", config);
            axios(config)
              .then((response) => {
                console.log(response.data);
                if (response.data.data == "notexist") {
                  resolve(true);
                } else if (response.data.data == "exist") {
                  this.$bvToast.toast("This GR number is already registerd!", {
                    title: "Error!",
                    variant: "danger",
                    solid: true,
                  });
                  reject();
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          } else {
            resolve(true);
          }
        }
      });
    },
    validationFamilyInfo() {
      return new Promise((resolve, reject) => {
        resolve(true);
        // this.CheckCnic();
        // this.CheckMCnic();
        // this.CheckEmail();
        // if (
        //   this.CheckCnic() == false ||
        //   this.CheckMCnic() == false ||
        //   this.CheckEmail() == false
        // ) {
        //   reject(false);
        // } else {
        //   resolve(true);
        // }
      });
    },
    validationEmergency() {
      return new Promise((resolve, reject) => {
        resolve(true);
        // this.CheckEmerName();
        // this.CheckEmerRelation();
        // this.CheckEmerContact();
        // if (
        //   this.CheckEmerName() == false ||
        //   this.CheckEmerRelation() == false ||
        //   this.CheckEmerContact() == false
        // ) {
        //   reject(false);
        //   console.log("rej");
        // } else {
        //   resolve(true);
        // }
      });
    },
    async shortcutSubmit() {
      // console.log("check", this.myObj.date_of_registraion);
      this.check = 0; // due to save button click
      this.CheckName();
      this.CheckFName();
      // this.CheckDob();
      this.CheckClassAdmit();
      this.checktestDate();
      this.CheckGrNo();
      this.CheckAdmitSec();

      if (
        this.CheckName() == false ||
        this.CheckFName() == false ||
        // this.CheckDob() == false ||
        this.CheckClassAdmit() == false ||
        this.CheckAdmitSec() == false ||
        this.checktestDate() == false ||
        this.CheckGrNo() == false
      ) {
        this.$bvToast.toast("Please fill the form correctly", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else {
        this.request = true;

        if (this.myObj.date_of_birth == "") {
          this.myObj.date_of_birth = null;
        }
        if (this.myObj.date_of_registraion == "") {
          this.myObj.date_of_registraion = null;
        }
        this.myObj.grNo = this.myObj.grNo.toString();
        if (this.myObj.family_code) {
          this.myObj.family_code = parseInt(this.myObj.family_code);
        } else this.myObj.family_code = 0;

        this.myObj.class_current = this.myObj.class_admitted;
        this.myObj.section_current = this.myObj.section_admitted;

        this.myObj.monthly_income = isNaN(parseInt(this.myObj.monthly_income))
          ? 0
          : parseInt(this.myObj.monthly_income);

        var status = await this.post({
          url:
            this.$store.state.domain +
            "students/save?db=" +
            this.$store.state.userData.db,
          body: this.myObj,
          message: "Student added successfully.",
          context: this,
          token: this.token,
          savingStudent: true,
        });
        this.request = false;
        if (status) {
          this.myObj = {
            id: 0,
            grNo: "",
            family_code: null,
            name: null,
            father_name: null,
            gender: null,
            picture: null,
            date_of_birth: null,
            date_of_birth_words: null,
            date_of_admission: null,
            blood_group: null,
            nationality: null,
            religion: null,
            place_of_birth: null,
            mother_tongue: null,
            address_current: null,
            address_permanent: null,
            contact_student: null,
            contact_father: null,
            contact_mother: null,
            contact_home: null,
            contact_tutor: null,
            last_institute: null,
            last_class: null,
            last_session: null,
            last_result: null,
            last_note: null,
            class_admitted: 0,
            cls_admitted: null,
            class_current: null,
            cls_current: null,
            section_admitted: 0,
            section_current: 0,
            roll_number: null,
            house: null,
            rfid_number: null,
            bform_number: null,
            date_of_registraion: new Date().toJSON(),
            date_of_readmission: null,
            father_cnic: null,
            father_education: null,
            father_occupation: null,
            monthly_income: null,
            office_phone: null,
            office_address: null,
            father_email: null,
            father_employer: null,
            mother_name: null,
            mother_cnic: null,
            mother_education: null,
            mother_occupation: null,
            mother_office_phone: null,
            mother_office_address: null,
            mother_email: null,
            mother_employer: null,
            guardian_name: null,
            guardian_cnic: null,
            guardian_education: null,
            guardian_occupation: null,
            guardian_office_phone: null,
            guardian_office_address: null,
            guardian_email: null,
            guardian_employer: null,
            guardian_relationship: null,
            emergency_name: null,
            emergency_contact: null,
            emergency_relationship: null,
            physical_handicap: null,
            allergies: null,
            remarks: null,
            monthly_fee: 0,
            discount_percentage: 0,
            challan_due_date: null,
            docs: "",
            status: "registered",
            campusID: parseInt(this.cId) || this.$store.state.userData.cId,
          };
          this.documents = [];
          this.cleanStates();
          this.check = 1;
          Swal.fire({
            title: "Thank you for submitting the form!",
            text: "We appreciate your interest and will get back to you as soon as possible.",
            icon: "success",
          });
        } else {
          this.$bvToast.toast("Something went wrong!", {
            title: "Error!",
            variant: "danger",
            solid: true,
          });
          this.request = false;
          this.check = 1;
        }
      }
    },
    selectMoreFile() {
      //this.moreFiles = this.$refs.mfile.files;
      if (this.$refs.mfile.files.length !== 0) {
        this.moreloading = true;
        var formdata = new FormData();
        for (var i = 0; i < this.$refs.mfile.files.length; i++) {
          formdata.append("file", this.$refs.mfile.files[i]);
        }

        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };

        fetch("https://upload.myskool.app", requestOptions)
          .then((response) => response.json()) //2
          .then((result) => {
            console.log();
            //this.moreImages.push(result.myresp);

            for (var i = 0, len = result.myresp.length; i < len; ++i) {
              this.moreloading = false;
              var fn = result.myresp[i].path;
              this.mydocs.push({
                path: fn,
                orginal: result.myresp[i].originalname,
              });
            }
            console.log(this.mydocs);
          })

          .catch((error) => console.log("error", error));
      }
    },
    deleteLogo() {
      //console.log(0);
      this.myObj.picture = null;
      this.logoloading = false;
      this.$refs.fileProfile.value = null;
    },
    selectlogo() {
      // console.log("Osama");
      var axios = require("axios");

      this.fileProfile = this.$refs.fileProfile.files[0];
      //console.log(this.fileProfile.name);

      //console.log(this.$refs.fileProfile.files[0], "!!!!!!!!!!" );
      //Upload cover
      if (this.$refs.fileProfile.files[0] !== "") {
        this.logoloading = true;

        let formData = new FormData();
        formData.append("file", this.$refs.fileProfile.files[0]);
        //console.log(this.fileProfile);
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            console.log(e.data);
            // this.file = undefined;
            var fn1 = e.data.myresp[0].path;
            // console.log(fn1);
            this.plus = fn1;
            this.myObj.picture = this.plus;
            //
            // console.log(this.plus);
            console.log(this.myObj.picture);
            this.logoloading = "loaded";

            //  console.log(this.loading);
          });
      }
    },
    cleanStates() {
      var elem = this.$refs["name"];
      elem.state = undefined;
      var elem = this.$refs["fname"];
      elem.state = undefined;
      var elem = this.$refs["dob"];
      elem.state = undefined;
      var elem = this.$refs["grNo"];
      elem.state = undefined;
      //   var elem = this.$refs["monthly_fee"];
      //   elem.state = undefined;
      // var elem = this.$refs["class_admitted"];
      // elem.state = undefined;
      // var elem = this.$refs["section_admitted"];
      // elem.state = undefined;
      // var elem = this.$refs["class_current"];
      // elem.state = undefined;
      // var elem = this.$refs["section_current"];
      // elem.state = undefined;
      // this.admittedSections = [];
      // this.currentSections = [];
    },
    CheckDiscount() {
      var elem = this.$refs["discount_percentage"];
      if (this.myObj.discount_percentage == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckAdmitSec() {
      // console.log(this.myObj.section_admitted);
      var elem = this.$refs["section_admitted"];
      if (this.myObj.section_admitted == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCurrentSec() {
      console.log(this.myObj.section_current);
      var elem = this.$refs["section_current"];
      if (this.myObj.section_current == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checktestDate() {
      if (this.$route.params.obj) {
        console.log(this.myObj.test_date);
        var elem = this.$refs["test_date"];
        if (this.myObj.test_date == "" || this.myObj.test_date == null) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      }
      return true;
    },
    CheckClassAdmit() {
      var elem = this.$refs["class_admitted"];
      if (this.myObj.class_admitted == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckClassCurrent() {
      var elem = this.$refs["class_current"];
      if (this.myObj.class_current == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.name == "" || this.myObj.name < 3) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckFName() {
      var elem = this.$refs["fname"];
      if (this.myObj.father_name == "" || this.myObj.father_name < 3) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDob() {
      var elem = this.$refs["dob"];
      if (this.myObj.date_of_birth == "" || this.myObj.date_of_birth == null) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckFContact() {
      // console.log(this.myObj.contact_father);
      // console.log(this.myObj.contact_father.length);
      if (this.myObj.contact_father.length == 12) {
        this.fphoneerror = false;
        return true;
      } else {
        this.fphoneerror = true;
        return false;
      }
    },
    CheckMContact() {
      // console.log(this.myObj.contact_mother);
      // console.log(this.myObj.contact_mother.length);
      if (this.myObj.contact_mother.length == 12) {
        this.mphoneerror = false;
        return true;
      } else {
        this.mphoneerror = true;
        return false;
      }
    },
    CheckContact() {
      // console.log(this.myObj.contact_student);
      // console.log(this.myObj.contact_student.length);
      if (this.myObj.contact_student.length == 12) {
        this.phoneerror = false;
        return true;
      } else {
        this.phoneerror = true;
        return false;
      }
    },
    CheckCnic() {
      var elem = this.$refs["fCnic"];
      if (this.myObj.father_cnic == null || this.myObj.father_cnic == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckMCnic() {
      var elem = this.$refs["mCnic"];
      if (this.myObj.mother_cnic == null || this.myObj.mother_cnic == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckEmail() {
      var elem = this.$refs["fEmail"];
      if (this.myObj.father_email == null || this.myObj.father_email == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckGCnic() {
      var elem = this.$refs["gCnic"];
      if (this.myObj.guardian_cnic == null || this.myObj.guardian_cnic == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckGEmail() {
      var elem = this.$refs["gEmail"];
      if (
        this.myObj.guardian_email == null ||
        this.myObj.guardian_email == ""
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckEmerName() {
      var elem = this.$refs["emerName"];
      if (
        this.myObj.emergency_name == null ||
        this.myObj.emergency_name == ""
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckEmerRelation() {
      var elem = this.$refs["emerRel"];
      if (
        this.myObj.emergency_relationship == null ||
        this.myObj.emergency_relationship == ""
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckEmerContact() {
      if (this.myObj.emergency_contact.length == 12) {
        console.log(this.myObj.emergency_contact.length);
        this.emerPhoneerror = false;
        return true;
      } else {
        this.emerPhoneerror = true;
        return false;
      }
    },
    CheckFees() {
      const regex = /^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/;
      var elem = this.$refs["monthly_fee"];
      if (regex.test(this.myObj.monthly_fee)) {
        if (this.myObj.monthly_fee < 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else {
        return (elem.state = false);
      }
    },
    CheckGrNo() {
      if (this.$route.params.obj) {
        var elem = this.$refs["grNo"];
        if (this.myObj.grNo == null || this.myObj.grNo == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      }
    },
    CalculateDiscount() {
      // var re = /^((100)|(\d{1,2}(.\d*)?))%$/
      if (
        this.myObj.discount_percentage < 0 ||
        this.myObj.discount_percentage > 100
      ) {
        this.myObj.discount_percentage = 0;
      } else {
        var percent = this.myObj.discount_percentage / 100;
        var discount = percent * this.myObj.monthly_fee;
        this.netAmount = this.myObj.monthly_fee - discount;
      }

      // console.log(netAmount);
    },
    FillSections(text) {
      //return console.log(this.classes);
      // console.log(this.myObj.class_admitted);

      this.classes.forEach((elem) => {
        if (elem.cls.id === this.myObj.class_admitted) {
          if (elem.sections.length != 0) {
            this.admittedSections = elem.sections;
          } else {
            // console.log("null");
            this.admittedSections = [];
          }
        }
      });
      if (text !== "filled") this.myObj.section_admitted = 0;
      // console.log("Thisis", this.admittedSections);
    },
    FillSectionsCurrent(text) {
      // console.log(this.myObj.class_current);

      this.classes.forEach((elem) => {
        if (elem.cls.id === this.myObj.class_current) {
          if (elem.sections.length != 0) {
            this.currentSections = elem.sections;
          } else {
            // console.log("null");
            this.currentSections = [];
          }
        }
      });
      if (text !== "filled") this.myObj.section_current = 0;
      console.log("Thisis", this.currentSections);
    },
    async LoadStudent() {
      var obj = {
        url:
          this.$store.state.domain +
          "Students/GetSelected?id=" +
          this.$route.params.id +
          "&db=" +
          this.$store.state.userData.db,
        token: this.token,
      };
      this.myObj = await this.get(obj);
      console.log("obj:", this.myObj);
      if (this.myObj.picture !== null && this.myObj.picture !== "") {
        this.plus = this.myObj.picture;
        this.logoloading = "loaded";
      }
      if (this.myObj.docs !== null && this.myObj.docs !== "") {
        this.documents = this.myObj.docs.split(",");
        // console.log(this.documents);
      }
      this.$store.commit("stdStatus", this.myObj.status.toLowerCase());
      this.LoadData();
    },
    async LoadInit() {
      var obj = {
        url:
          this.$store.state.domain +
          "Students/AddInit?campusId=" +
          this.$store.state.userData.cId +
          "&db=" +
          this.$store.state.userData.db,
        token: this.token,
      };
      let initData = await this.get(obj);
      // console.log("data:", initData);
      this.myObj.grNo = initData.newG;
      this.myObj.family_code = initData.fCode;
    },
    LoadData() {
      this.dataloading = true;

      var axios = require("axios");
      var config = {
        method: "get",
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          (this.db || this.$store.state.userData.db) +
          "&cID=" +
          (this.cId || this.$store.state.userData.cId) +
          "&dID=0",
        headers: {
          Authorization: "bearer " + this.token,
        },
      };

      axios(config)
        .then((response) => {
          // console.log(response.data.data);
          this.classes = [];
          this.classes = response.data.data;
          this.classesOptions = [];
          this.classes.forEach((el) => this.classesOptions.push(el.cls));
          this.FillSections("filled");
          this.dataloading = false;
        })
        .catch(function (error) {
          console.log(error);
          this.dataloading = false;
        });
    },
    async LoadSchool() {
      var obj = {
        url:
          this.$store.state.domain +
          "Campuses/GetSelected?id=" +
          (this.cId || this.$store.state.userData.cId) +
          "&db=" +
          (this.db || this.$store.state.userData.db),
        token: this.token,
      };
      this.school = await this.get(obj);
      // console.log("sch:", this.school);
    },
  },
};
</script>
<style>
.heading {
  padding: 0;
  margin: 2.171303074670571vw 0;
  /* text-shadow: -4px 0 5px white, 0 4px 5px white, 4px 0 5px white,
      0 -4px 5px white; */
}
.input-form {
  margin: 0 70px;
  background: #fff;
}
#fileInput .custom-file-label {
  color: #707070;
  border: 1px dashed #33312f;
  text-align: center;
  padding: 2.5rem 1.5rem;
  width: 100%;
}
#fileInput input {
  display: none;
}
#imgInput input {
  display: none;
}
#imgInput {
  /* height: 8rem; */
  width: 50%;
}
#imgInput div {
  height: 8vw;
}
#imgInput .custom-file-label {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.type {
  background-color: red;
  border-radius: 10px;
  font-size: 0.8rem;
  padding: 10px;
  color: white;
}
.filename {
  padding-left: 25px;
}
.delete {
  margin: 0 0 0 auto;
  color: red;
}
#checkbox-1 {
  margin-right: 10px !important;
}
.submit {
  background-color: #00c192;
  color: #fff;
  width: 16.5vw;
  height: 4vw;
  font-weight: bold;
  border-color: #00c192;
}
.form-file-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 20px;
}
/* #prop-det, #prop-det div {
    height: 100%;
  } */

.item-group {
  width: 50%;
  /* margin-top: 2.269399707174231vw; */
}
.inputfields,
.inputfields:focus {
  border: 1px solid #33312f;
  border-radius: 0.5856515373352855vw;
  height: 2.562225475841874vw;
  outline: none !important;
  resize: none;
}
textarea.form-control {
  height: 8vw !important;
}
@media only screen and (max-width: 990px) {
  .mob-setting {
    padding: 0 1rem !important;
    width: 100vw !important;
    margin: 0 auto !important;
  }
  .mobile-view {
    margin: 0 !important;
  }
  /* .col-form-label,
  .form-file-text,
  .custom-control-label {
    font-size: 3vw;
  } */
  .item-group {
    margin-top: 0;
  }
  .margin-top-mobile {
    margin-top: 5vw;
  }
  /* .col-form-label {
    display: none;
  } */
  .form-input-mobile-text {
    width: 92vw;
    margin: 10px auto;
    border-radius: 2.8301610541727673vw;
    height: 12.781844802342606vw;
    font-size: 5.171303074670571vw;
    background-color: #f2f2f2 !important;
    border: 1px solid #33312f;
  }
  textarea.form-control {
    height: 12.781844802342606vw !important;
  }
  .form-input-mobile-text:focus {
    background-color: #00c192 !important;
    color: #fff !important;
  }
  .form-input-mobile-text::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(190, 190, 190);
    opacity: 1; /* Firefox */
    font-family: DINNextRoundedLTW01-Regular;
  }

  .form-input-mobile-text:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgb(190, 190, 190);
  }

  .form-input-mobile-text::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgb(190, 190, 190);
  }

  .form-input-mobile-text:focus::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
  }
  .form-file-input-mobile {
    border-radius: 2.8301610541727673vw;
    height: 12.781844802342606vw;
    background-color: #f2f2f2 !important;
    color: #fff !important;
  }
  .custom-file-label {
    border-radius: 2.8301610541727673vw !important;
    height: 12.781844802342606vw;
    background-color: #f2f2f2 !important;
    color: #000 !important;
    height: 40vw;
    border: 1px solid #33312f;
  }
  .submit {
    width: 50vw;
    height: 10vw;
  }
  .filename {
    font-size: 3vw;
  }
  .shadowHead {
    font-size: 8vw !important;
  }
  #imgInput div {
    height: 29vw !important;
  }
  .item-group {
    height: 30vw !important;
  }
}

@media only screen and (min-width: 1366px) {
  .submit {
    width: 15.5vw;
    height: 7.8vh;
  }
}
@media only screen and (min-width: 1440px) {
  .submit {
    width: 14.7vw;
    height: 6.7vh;
  }
}
@media only screen and (min-width: 1536px) {
  .submit {
    width: 13.8vw;
    height: 6.9vh;
  }
}
@media only screen and (min-width: 1920px) {
  .submit {
    width: 11vw;
    height: 5.5vh;
  }
}
#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
</style>
<style scoped>
.rcorners2 {
  border-radius: 100px;
  border: 4px solid var(--primary);
  padding: 2px;
}
.sticky {
  position: fixed;
  /* top: 0; */
  right: 2rem;
  /* margin-bottom: 30px; */
  /* position: fixed;
      bottom: 5%;
      z-index: 1031;  */
}
.file-uploader {
  position: relative;
  border: 1px solid #000;
  width: 100%;
  line-height: 30px;
  text-align: center;
  border-radius: 10px;
  font-family: "Helvetica", sans-serif;
  height: 70px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.file_upload {
  opacity: 0;

  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  /* IE 5-7 */
  filter: alpha(opacity=0);

  /* Netscape or and older firefox browsers */
  -moz-opacity: 0;

  /* older Safari browsers */
  -khtml-opacity: 0;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.file {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.file-type {
  background-color: #e62e2d;
  font-family: "Helvetica", sans-serif;
  color: white;
  border-radius: 10px;
  margin-right: 15px;
  height: 50px;
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.file-name {
  font-family: "Helvetica", sans-serif;
  margin: 0px;
}

.file-delete i {
  color: #e62e2d;
  font-size: 2rem;
  cursor: pointer;
}
.modal-header {
  display: none;
}
.details-adjust h5 {
  word-wrap: break-word;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
